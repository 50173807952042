$landing-top-height: 12rem;
$business-offerings-art-box: 4rem;
$business-offerings-art-box-mobile: 2.5rem;

.landing-container {
  width: 100%;
  background-color: $white;
}

.banner {
  width: 100%;
  height: calc(100vh);
  padding-top: $header-height-with-banner;
  background-color: $white;
  background: url('../assets/images/landing-banner-bg.svg');
  background-size: cover;
  backdrop-filter: saturate(1.3) contrast(1.1);

  .landing-top {
    width: 100%;
    padding: 0 $side-padding-desktop;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: red;

    height: $landing-top-height;

    .landing-top-title {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-top: 2rem;

      h1 {
        color: $grey900;
      }

      h1 .lower-highlight {
        @extend h1;
        color: inherit;
        border-bottom: 0.4rem solid $orange;
      }
    }

    .landing-top-info {
      display: flex;
      flex-direction: column;
      width: calc(40% - 2rem);

      // p {
      //   color: $grey600;
      // }

      // .landing-actions {
      //   display: flex;
      //   align-items: center;

      //   button {
      //     margin-right: 0.5rem;
      //   }
      // }
    }
  }

  .landing-carousel {
    width: 100%;
    min-height: 5rem;
    overflow: hidden;
    padding: 1rem 0;
    height: calc(100vh - $header-height-with-banner - $landing-top-height);
    // background-color: gold;

    .carousel-slider {
      width: 100%;
      display: flex;
      transition: 1500ms ease;
      white-space: nowrap;
      position: relative !important;
      // background-color: hotpink;

      z-index: 0;

      gap: 1.5rem;
      height: calc(100% - 2rem);
      transform: none;
      // margin-left: calc(-1.5 * $side-padding-desktop);

      a {
        height: 100%;
        &:nth-child(even) {
          .carousel-item {
            aspect-ratio: 1.5 / 1;
          }
        }

        &:nth-child(odd) {
          .carousel-item {
            aspect-ratio: 0.75 / 1;
          }
        }

        &:nth-child(1) {
          margin-left: $side-padding-desktop;
        }
      }

      .carousel-item {
        display: flex;
        position: relative;
        border-radius: $border-radius-lg;
        overflow: hidden;
        border: 1px solid $grey300;
        height: 100%;
        transition: 250ms ease-out;

        &:hover {
          transform: scale(1.02) translateY(-0.25rem);
          transition: transform 300ms ease-out;
        }

        .carousel-image {
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
          top: 0;
          left: 0;
          background-color: transparent;
          backdrop-filter: saturate(2.5) blur(5px) contrast(0.975) brightness(0.975);
          // box-shadow: inset 0px 0px 0px 1px $grey300;
          border-radius: $border-radius-lg;
          position: relative;

          .image-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
            background: radial-gradient(transparent 70%, rgba($grey900, 0.075));
          }

          img {
            width: 100%;

            object-fit: cover;
            display: block;
            position: relative;
          }
        }

        .carousel-video {
          width: 100%;
          height: 100%;
          background-color: transparent;
          backdrop-filter: saturate(2.5) blur(5px) contrast(0.975) brightness(0.975);
          overflow: hidden;
          // border: 1px $grey300 inset;
          // box-shadow: inset 0px 0px 0px 1px $grey300;
          border-radius: $border-radius-lg;
          position: relative;

          .image-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 100;
            background: radial-gradient(transparent 80%, rgba($grey800, 0.1));
          }

          video {
            position: absolute;
            border-radius: $border-radius-lg;
            overflow: hidden;
            width: calc(100% - 2.5rem);
            max-height: calc(100% - 2.5rem - 3rem);
            top: 1.25rem;
            left: 1.25rem;
            object-fit: cover;
            object-position: top center;
            filter: saturate(1.25);
            box-shadow: 0 0 1.5rem 4px rgba($color: $grey800, $alpha: 0.15);
          }
        }

        .carousel-info {
          position: absolute;
          bottom: 1.25rem;
          left: 1.25rem;
          display: flex;
          align-items: center;
          background-color: rgba($color: $grey800, $alpha: 1);
          backdrop-filter: blur(5px);
          padding: 0.3rem 0.8rem;
          border-radius: 2rem;
          transition: 200ms ease-out;
          max-width: calc(100% - 3rem);
          overflow: hidden;
          z-index: 101;
          box-shadow: 0 0 1rem 3px rgba($color: $grey800, $alpha: 0.1);

          small {
            margin: 0;
            color: $grey100;
            font-size: 0.8rem;
            display: flex;
            align-items: center;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            position: relative;
            padding-right: 1.5rem;

            svg {
              width: 0.75rem;
              height: 0.75rem;
              stroke: $grey100;
              position: absolute;
              top: 50%;
              right: 0.3rem;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }

  .banner-strip {
    width: 100%;
    height: 0.5rem;
    background-color: $orange;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

.about-container {
  display: flex;
  flex-direction: column;
  padding: 2rem $side-padding-desktop;
  background-color: $white;
  width: 100%;
  border-bottom: 0.4rem solid $orange;

  h5 {
    .grey600 {
      @extend h5;
      color: $grey500;
    }
  }

  h3 {
    .grey600 {
      @extend h3;
      color: $grey600;
    }
  }

  .about-main {
    width: 100%;
    display: flex;
    padding: 3rem 0;
    gap: 3rem;

    // .about-art {
    //   width: 60%;
    //   display: grid;
    //   grid-template-columns: repeat(3, 1fr);
    //   grid-gap: 1rem;
    //   height: fit-content;

    //   .about-art-item {
    //     background-color: $grey200;
    //     aspect-ratio: 1;
    //     border-radius: $border-radius-lg;
    //     overflow: hidden;

    //     &:nth-child(3) {
    //       background-color: transparent;
    //     }

    //     &:nth-child(5) {
    //       background-color: transparent;
    //     }

    //     &:nth-child(6) {
    //       background-color: $tan;
    //     }

    //     img {
    //       width: 100%;
    //       height: 100%;
    //       object-fit: cover;
    //       border-radius: $border-radius-lg;
    //       border: 1px solid $grey200;

    //       position: relative;

    //       &::before,
    //       &::after {
    //         content: '';
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         width: 100%;
    //         height: 100%;
    //       }

    //       &::before {
    //         background: radial-gradient(
    //           ellipse at center,
    //           rgba(0, 0, 0, 0) 0%,
    //           rgba(0, 0, 0, 1) 100%
    //         );
    //       }

    //       &::after {
    //         background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    //       }
    //     }
    //   }

    //   img {
    //     width: 100%;
    //     height: 100%;
    //     object-fit: cover;
    //     border-radius: $border-radius-lg;
    //   }
    // }

    // .about-info {
    //   display: flex;
    //   flex: 1;
    //   flex-direction: column;
    //   margin-left: 4rem;
    // }

    .about-title {
      width: 50%;
      padding: 1.5rem 2rem;
      background: url('../assets/images//landing-banner-bg.svg');
      background-size: cover;
      display: flex;
      flex-direction: column;
      border-radius: $border-radius-lg;
      border: 1px solid $grey300;
      backdrop-filter: blur(5px) saturate(1.2);
    }

    .about-main-grid {
      flex: 1;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 3rem;
      padding: 2rem 0;
    }

    .about-main-item {
      display: flex;
      flex-direction: column;

      .about-main-item-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        border-radius: $border-radius-lg;
        border: 1px solid $grey300;
        backdrop-filter: blur(5px) saturate(1.2);
        background: url('../assets/images//landing-banner-bg.svg');
        background-size: cover;
        margin-bottom: 0.5rem;

        svg {
          width: 1.4rem;
          height: 1.4rem;
          stroke: $grey800;
        }
      }
    }
  }
}

.digital-offerings-container {
  display: flex;
  flex-direction: column;
  padding: 2rem $side-padding-desktop;
  background-color: $white;
  width: 100%;
  border-bottom: 0.4rem solid $orange;

  .digital-offerings-header {
    display: flex;
    width: 100;
    flex-direction: column;
  }

  .digital-offerings-listing {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
    padding: 3rem 0 0 0;

    .digital-offering-item {
      // height: 40rem;
      // background-color: azure;
      display: flex;
      flex-direction: column;

      h5 {
        height: 5.5rem;
        // background-color: red;
        // display: flex;
        align-items: flex-start;
        margin-bottom: 0;
        text-decoration: none;

        span {
          @extend h5;
          border-bottom: 0.2rem solid $orange;
        }
      }

      p {
        margin: 0.75rem 0;
        text-decoration: none;
        height: 6rem;

        &.first-line {
          height: 5rem;
          // background-color: salmon;
        }
      }

      img {
        width: 100%;
        aspect-ratio: 3 / 2;
        object-fit: cover;
        margin: 0.5rem 0;
        border-radius: $border-radius-lg;

        box-shadow: inset 0 0 2rem 2rem black;
      }
    }
  }
}

.business-offerings-container {
  display: flex;
  flex-direction: column;
  padding: 2rem $side-padding-desktop;
  background-color: $grey100;
  position: relative;
  width: 100%;
  border-bottom: 0.4rem solid $orange;

  .business-offerings-header {
    display: flex;
    width: 100;
    flex-direction: column;
  }

  .business-offerings-main {
    // min-height: 100vh;
    width: 100%;
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;

    .business-offerings-art {
      width: fit-content;
      height: 50vh;
      // border: 1px solid black;
      display: flex;
      // background-color: mistyrose;
      overflow: hidden;
      position: relative;

      .business-offerings-art-column {
        width: $business-offerings-art-box;
        height: 50vh;
        // background-color: lawngreen;
        position: relative;
        margin-right: $business-offerings-art-box / 2;

        .business-offerings-art-slider {
          display: flex;
          flex-direction: column;
          // margin-right: $business-offerings-art-box / 2;
          animation: art-scroll 100s linear infinite;
          width: 100%;
          // background-color: bisque;
          height: 50vh;

          &:hover {
            animation-play-state: paused;
          }

          @keyframes art-scroll {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateY(calc(-250px * 9));
            }
          }

          @keyframes art-scroll-reverse {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateY(calc(250px * 9));
            }
          }

          .business-offerings-art-item {
            width: $business-offerings-art-box;
            height: $business-offerings-art-box;
            border-radius: $border-radius-lg;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            aspect-ratio: 1;
            margin-bottom: $business-offerings-art-box / 2;
            overflow: hidden;
            filter: brightness(0.95) saturate(1.2);

            svg {
              width: 1.6rem;
              height: 1.6rem;
              stroke: rgba($white, 1);
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        &:nth-child(odd) {
          .business-offerings-art-slider {
            animation-name: art-scroll-reverse;
          }
        }
      }

      .business-offerings-art-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top, $grey100 2%, rgba($white, 0), $grey100 98%);
        z-index: 10;
      }
    }

    .business-offerings-info {
      display: flex;
      flex-direction: column;
      margin-left: 3rem;
      flex: 1;
    }
  }

  .business-offerings-details {
    width: 100%;
    // position: relative;
    // display: flex;
    // flex-direction: column;
    // padding-bottom: 150vh;
    // min-height: 100vh;
    // position: fixed;
    // position: sticky;
    // top: $header-height-with-banner;
    // background-color: $white;
  }
}

.industries-container {
  width: 100%;
  display: block;
  // flex-direction: column;
  padding: 2rem $side-padding-desktop;
  // position: relative;
  min-height: 200vh;

  .industries-float-container {
    width: 100%;
    position: sticky;
    z-index: 50;

    top: $header-height-with-banner;
    // bottom: 50vh;
    padding: 1rem 0;
    background-color: $white;

    .industries-float {
      display: flex;
      align-items: center;
      padding: 0 0;

      .search {
        margin-right: 1.5rem;
        cursor: pointer;

        svg {
          width: 1.2rem;
          height: 1.2rem;
          stroke: $grey900;
        }
      }

      .industries-menu {
        display: flex;
        width: fit-content;
        align-items: center;

        a {
          font-size: 0.9rem;
          color: $grey500;
          cursor: pointer;
          padding: 0.4rem 0.8rem;
          display: flex;
          align-items: center;
          text-decoration: none;
          margin: 0 0.5rem;
          font-family: $body;
          border-radius: $border-radius-lg;
          transition: 350ms ease-out;
          border: 1px solid $grey300;

          &:first-child {
            margin-left: 0;
          }

          &.active {
            color: $grey100;
            background-color: $grey800;
            transition: 350ms ease-out;

            svg {
              stroke: $grey100;
            }
          }

          svg {
            width: 1.4rem;
            height: 1.4rem;
            margin-right: 0.5rem;
            // padding-bottom: 0.25rem;
            stroke: $grey700;
            transform: none;
            margin-left: 0;
          }

          // &:hover {
          //   &:not(.active) {
          //     background-color: $grey100;
          //     color: $grey700;
          //   }
          //   svg {
          //     transform: none;
          //   }
          // }
        }
      }
    }
  }

  .industry-container {
    margin-bottom: 1rem;
    // background-color: salmon;
    width: 100%;
  }

  #BFSI {
    display: flex;
    flex-direction: row;
    width: 100%;

    .bfsi-info {
      width: 50%;
      margin-right: 2rem;
      display: flex;
      flex-direction: column;
      height: fit-content;
    }

    .finaqua-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      border-radius: $border-radius-lg;
      overflow: hidden;
      margin: 0;
      margin-top: 2rem;
      border: 1px solid $grey300;

      .finaqua-video-container {
        // height: 50vh;
        width: 100%;
        aspect-ratio: 16 / 9;

        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
        }

        .finaqua-video-play-button {
          position: absolute;
          bottom: 1rem;
          right: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 2.5rem;
          background-color: $grey800;
          box-shadow: 0 0 5px 2px rgba($grey800, 0.25);
          padding: 0.25rem 1.2rem;
          cursor: pointer;

          svg {
            stroke: $grey200;
            margin-right: 0.75rem;
            width: 1.3rem;
            height: 1.3rem;
            transform: none;
          }

          p {
            color: $grey200;
            font-size: 0.85rem;
          }
        }
      }

      .finaqua-info {
        padding: 1.5rem;
        padding-top: 1rem;
        flex: 1;
        background-color: $grey800;

        h5 {
          color: $white;
          letter-spacing: 0.5px;

          .underline {
            // @extend h5;
            color: inherit;
            letter-spacing: inherit;
            text-decoration: underline;
          }
        }
        p {
          color: $grey300;
          font-size: 0.9rem;
        }

        small {
          font-style: italic;
          color: $grey400;
          font-size: 0.75rem;
        }

        .check {
          background-color: transparent;

          svg {
            stroke: $grey200;
            margin-top: 0.5rem;
          }
        }
      }
    }
  }
}

.picture-gallery {
  width: 100%;
  object-fit: cover;
  margin-top: -8rem;
}

.success-stories-container {
  padding: 2rem $side-padding-desktop;
  overflow: hidden;
  width: 100%;
  padding-bottom: 2rem;

  .success-stories-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .success-stories-arrows {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;

    button {
      svg {
        margin-right: 0;
      }
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }

  .reviews-slider {
    width: 100%;
    // background-color: red;
    // height: 20rem;
    display: flex;
    margin-top: 2rem;
  }

  .testimonial-item {
    display: flex;
    height: 100%;
    //padding: 2rem;
    padding: 0.5rem;
    flex-direction: column;
    // width: 50vw;
    width: 100%;
    border: 1px solid $grey300;
    border-radius: $border-radius-lg;
    overflow: hidden;
    margin-right: 2rem;

    blockquote {
      margin: 0;
    }

    .testimonial-info {
      display: flex;
      //flex-direction: column;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;

      .author-photo {
        // width: 4.5rem;
        // height: 4.5rem;
        //padding: 0.5rem;
        border-radius: $border-radius-lg;
        display: flex;
        align-items: center;
        justify-content: center;
        //background-color: $grey200;
        //overflow: hidden;
        //margin-top: 1.5rem;

        // border: 1px solid $grey300;
        // backdrop-filter: blur(5px) saturate(1.4) contrast(1.4);
        // background: url('../assets/images/landing-banner-bg.svg');
        // background-size: cover;

        img {
          width: 100%;
          height: auto;
          // width: 100%;
          // height: 100%;
          // object-fit: cover;
          // border-radius: $border-radius-sm;
        }
      }
      h6 {
        color: $grey600;
        margin-bottom: -0rem;
        margin-top: 1rem;
      }
    }
  }
}

.business-services-grid {
  width: 100%;
  // background-color: honeydew;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2rem;
  margin: 1rem 0;
  margin-top: 1.5rem;

  a {
    width: 100%;
    height: 100%;
  }

  .business-services-item {
    background-color: transparent;
    width: 100%;
    height: 100%;
    // aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    border-radius: $border-radius-lg;
    overflow: hidden;
    justify-content: space-between;
    padding: 1.25rem;
    cursor: pointer;
    border: 1px solid $grey300;
    transition: 200ms ease-out;

    .business-services-item-main {
      height: fit-content;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &:hover {
      background-color: $grey200;
      transition: 200ms ease-out;
    }

    // img {
    //   width: 100%;
    //   aspect-ratio: 1 / 0.5;
    //   border-radius: $border-radius-lg;
    //   object-fit: cover;
    //   border-bottom-left-radius: 0;
    //   border-bottom-right-radius: 0;
    // }

    .business-services-item-icon {
      width: 2.75rem;
      height: 2.75rem;
      border-radius: $border-radius-lg;
      display: flex;
      align-items: center;
      justify-content: center;
      // background-color: $grey800;
      border: 1px solid $grey300;
      margin-bottom: 1.5rem;
      border-radius: $border-radius-lg;
      border: 1px solid $grey300;
      backdrop-filter: blur(5px) saturate(1.2);
      background: url('../assets/images//landing-banner-bg.svg');
      background-size: cover;

      svg {
        width: 1.25rem;
        height: 1.25rem;
        stroke: $grey800;
      }
    }

    .business-services-info {
      display: flex;
      flex-direction: column;

      h6 {
        margin-top: 0;
        font-size: 1.1rem;
      }
    }
  }
}

.for-digital {
  grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 720px) {
  .for-digital {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .landing-container {
    .banner {
      display: flex;
      flex-direction: column;
      height: calc(100svh);
      padding-bottom: 1rem;

      background-image: url('../assets/images/landing-banner-bg-mobile.svg');

      .banner-strip {
        display: none;
      }
      .landing-top {
        padding: 2rem $side-padding-mobile 0;
        // min-height: 20rem;
        flex: unset;
        height: fit-content;
        padding-top: 1rem;
        align-items: unset;

        .landing-top-title {
          padding-top: 1rem;
        }
      }
      .mobile-carousel {
        flex: 1;
        display: flex;
        min-width: 100%;
        overflow: hidden;
        max-height: unset;
        height: unset;
        // background-color: darkblue;
        padding: 2rem $side-padding-mobile 1rem $side-padding-mobile;

        .mobile-carousel-item {
          width: calc(100vw - 6 * $side-padding-mobile);
          height: 100%;
          background-color: transparent;
          border: 1px solid $grey300;
          // backdrop-filter: saturate(150%) blur(2px) contrast(1);
          border-radius: $border-radius-lg;
          margin-right: $side-padding-mobile;
          overflow: hidden;
          // background-color: lawngreen;
          position: relative;

          .mobile-carousel-image {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            top: 0;
            left: 0;
            background-color: transparent;
            backdrop-filter: saturate(2.5) blur(5px) contrast(0.975) brightness(0.975);
            // box-shadow: inset 0px 0px 0px 1px $grey300;
            border-radius: $border-radius-lg;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .mobile-carousel-video {
            width: 100%;
            height: 100%;
            background-color: transparent;
            backdrop-filter: saturate(2.5) blur(5px) contrast(0.975) brightness(0.975);
            overflow: hidden;
            // border: 1px $grey300 inset;
            // box-shadow: inset 0px 0px 0px 1px $grey300;
            border-radius: $border-radius-lg;
            position: relative;

            .image-overlay {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 100;
              background: radial-gradient(transparent 80%, rgba($grey800, 0.1));
            }

            video {
              position: absolute;
              border-radius: $border-radius-lg;
              overflow: hidden;
              width: calc(100% - 2.5rem);
              height: calc(100% - 2.5rem - 3rem);
              top: 1.25rem;
              left: 1.25rem;
              object-fit: cover;
              object-position: top center;
              filter: saturate(1.25);
              box-shadow: 0 0 1.5rem 4px rgba($color: $grey800, $alpha: 0.15);
            }
          }

          .mobile-carousel-info {
            position: absolute;
            bottom: 1.25rem;
            left: 1.25rem;
            display: flex;
            align-items: center;
            background-color: rgba($color: $grey800, $alpha: 1);
            backdrop-filter: blur(5px);
            padding: 0.3rem 0.8rem;
            border-radius: 2rem;
            transition: 200ms ease-out;
            max-width: calc(100% - 3rem);
            overflow: hidden;
            z-index: 89;
            box-shadow: 0 0 1.5rem 4px rgba($color: $grey800, $alpha: 0.25);

            small {
              margin: 0;
              color: $grey100;
              font-size: 0.85rem;
              display: flex;
              align-items: center;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              position: relative;
              padding-right: 1.5rem;

              svg {
                width: 0.75rem;
                height: 0.75rem;
                stroke: $grey100;
                position: absolute;
                top: 50%;
                right: 0.3rem;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
  }

  .about-container {
    display: flex;
    flex-direction: column;
    padding: 2rem $side-padding-mobile;
    background-color: $grey100;
    width: 100%;
    border-bottom: 0.4rem solid $orange;

    .about-main {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 1rem 0;
      padding-top: 0;

      .about-title {
        width: 100%;
        padding: 1.5rem;
      }

      .about-main-grid {
        padding-top: 0rem;
        grid-gap: 1.5rem;
        padding: 0 0.25rem;
      }

      .about-art {
        width: 100%;
        // display: grid;
        // grid-template-columns: repeat(3, 1fr);
        // grid-gap: 1rem;
        height: fit-content;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: $border-radius-lg;
        }
      }

      .about-info {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-left: 0;
        margin-top: 2rem;
      }
    }
  }

  .digital-offerings-container {
    display: flex;
    flex-direction: column;
    padding: 2rem $side-padding-mobile;
    background-color: $white;
    width: 100%;
    border-bottom: 0.4rem solid $orange;

    .digital-offerings-header {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    .digital-offerings-listing {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1.5rem;
      padding: 2rem 0 0 0;

      .digital-offering-item {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
          border-bottom: 1px solid $grey200;
          padding-bottom: 1rem;
        }

        h5 {
          height: auto;
          margin-top: 0;
          margin-bottom: 0.5rem;
          text-decoration: none;

          span {
            border-bottom: 0.2rem solid $orange;
          }
        }

        p {
          margin: 0.75rem 0;
          text-decoration: none;
          height: auto;
          margin-bottom: 0;

          &.first-line {
            height: auto;
            // background-color: salmon;
          }
        }

        img {
          width: 100%;
          aspect-ratio: 3 / 2;
          object-fit: cover;
          margin: 0.5rem 0;
          border-radius: $border-radius-lg;

          box-shadow: inset 0 0 2rem 2rem black;
        }
      }
    }
  }

  .business-offerings-container {
    display: flex;
    flex-direction: column;
    padding: 2rem $side-padding-mobile;
    background-color: $grey100;
    position: relative;
    width: 100%;
    border-bottom: 0.4rem solid $orange;

    .business-offerings-header {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    .business-offerings-main {
      // min-height: 100vh;
      width: 100%;
      padding: 0 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .business-offerings-art {
        width: calc($business-offerings-art-box-mobile * 5.5);
        height: calc(100vw - 2 * $side-padding-mobile);
        // border: 1px solid black;
        display: flex;
        // background-color: mistyrose;
        overflow: hidden;
        position: relative;
        transform: rotate(-90deg) translateY(4rem) translateX(2rem);

        .business-offerings-art-column {
          width: $business-offerings-art-box-mobile;
          height: 10rem;
          // background-color: lawngreen;
          position: relative;
          margin-right: $business-offerings-art-box-mobile / 2;

          .business-offerings-art-slider {
            display: flex;
            flex-direction: column;
            // margin-right: $business-offerings-art-box / 2;
            animation: art-scroll 100s linear infinite;
            width: 100%;
            // background-color: bisque;
            height: 100%;

            &:hover {
              animation-play-state: paused;
            }

            @keyframes art-scroll {
              0% {
                transform: translateX(0);
              }
              100% {
                transform: translateY(calc(-250px * 9));
              }
            }

            @keyframes art-scroll-reverse {
              0% {
                transform: translateX(0);
              }
              100% {
                transform: translateY(calc(250px * 9));
              }
            }

            .business-offerings-art-item {
              width: $business-offerings-art-box-mobile;
              height: $business-offerings-art-box-mobile;
              border-radius: $border-radius-lg;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: darkcyan;
              aspect-ratio: 1;
              margin-bottom: $business-offerings-art-box-mobile / 2;
              overflow: hidden;
              filter: brightness(0.95) saturate(1.2);

              svg {
                width: 1.2rem;
                height: 1.2rem;
                stroke: rgba($white, 1);
                transform: rotate(90deg);
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }

          &:nth-child(odd) {
            .business-offerings-art-slider {
              animation-name: art-scroll-reverse;
            }
          }
        }

        .business-offerings-art-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to top, $grey100 2%, rgba($white, 0), $grey100 98%);
          z-index: 10;
        }
      }

      .business-offerings-info {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-top: -3rem;
        flex: 1;
      }
    }

    .business-offerings-details {
      width: 100%;
      // position: relative;
      // display: flex;
      // flex-direction: column;
      // padding-bottom: 150vh;
      // min-height: 100vh;
      // position: fixed;
      // position: sticky;
      // top: $header-height-with-banner;
      // background-color: $white;
    }
  }

  .industries-container {
    width: 100%;
    display: block;
    // flex-direction: column;
    padding: 2rem $side-padding-mobile;
    // position: relative;
    min-height: unset;

    .industries-float-container {
      width: 100%;
      position: sticky;
      z-index: 50;

      top: $header-height-with-banner;
      // bottom: 50vh;
      padding: 1rem 0;
      background-color: $white;

      .industries-float {
        display: flex;
        align-items: center;
        padding: 0 0;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;

        .search {
          margin-right: 1.5rem;
          cursor: pointer;

          svg {
            width: 1.2rem;
            height: 1.2rem;
            stroke: $grey900;
          }
        }

        .industries-menu {
          display: flex;
          width: 100%;
          align-items: center;

          a {
            font-size: 1rem;
            color: $grey500;
            cursor: pointer;
            padding: 0.4rem 0.8rem;
            display: flex;
            align-items: center;
            text-decoration: none;
            margin: 0 0.5rem;
            font-family: $body;
            border-radius: $border-radius-lg;
            transition: 350ms ease-out;
            border: 1px solid $grey300;
            white-space: nowrap;

            &:first-child {
              margin-left: 0;
            }

            &.active {
              color: $grey100;
              background-color: $grey800;
              transition: 350ms ease-out;

              svg {
                stroke: $grey100;
              }
            }

            svg {
              width: 1.4rem;
              height: 1.4rem;
              margin-right: 0.5rem;
              // padding-bottom: 0.25rem;
              stroke: $grey700;
              transform: none;
              margin-left: 0;
            }

            // &:hover {
            //   &:not(.active) {
            //     background-color: $grey100;
            //     color: $grey700;
            //   }
            //   svg {
            //     transform: none;
            //   }
            // }
          }
        }
      }
    }

    .industry-container {
      margin-bottom: 1rem;
      // background-color: salmon;
      width: 100%;
    }

    #BFSI {
      display: flex;
      flex-direction: column;
      width: 100%;

      .bfsi-info {
        width: 100%;
        margin-right: 0;
        display: flex;
        flex-direction: column;
        height: fit-content;
      }

      .finaqua-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        border-radius: $border-radius-lg;
        overflow: hidden;
        margin: 0;
        margin-top: 2rem;
        border: 1px solid $grey300;

        .finaqua-video-container {
          // height: 50vh;
          width: 100%;
          aspect-ratio: 16 / 9;

          overflow: hidden;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
          }

          .finaqua-video-play-button {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2.5rem;
            background-color: $grey800;
            box-shadow: 0 0 5px 2px rgba($grey800, 0.25);
            padding: 0.2rem 1rem;
            padding-left: 0.5rem;
            cursor: pointer;

            svg {
              stroke: $grey200;
              margin-right: 0.75rem;
              width: 1.3rem;
              height: 1.3rem;
              transform: none;
            }

            p {
              color: $grey200;
              font-size: 0.85rem;
            }
          }
        }

        .finaqua-info {
          padding: 1.5rem;
          padding-top: 1rem;
          flex: 1;
          background-color: $grey800;

          h5 {
            color: $white;
            letter-spacing: 0.5px;

            .underline {
              // @extend h5;
              color: inherit;
              letter-spacing: inherit;
              text-decoration: underline;
            }
          }
          p {
            color: $grey300;
            font-size: 1.12rem;
          }

          small {
            font-style: italic;
            color: $grey400;
            font-size: 0.75rem;
          }

          .check {
            background-color: $grey600;
          }
        }
      }
    }
  }

  .picture-gallery {
    width: 100%;
    object-fit: cover;
    margin-top: -2rem;
  }

  .success-stories-container {
    padding: 2rem $side-padding-mobile;
    overflow: hidden;
    width: 100%;

    .success-stories-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .success-stories-arrows {
      display: none;
      position: absolute;
      width: calc(100vw);
      top: 50%;
      left: -1 * $side-padding-mobile;
      padding: 0 0.5rem;
      transform: translateY(-50%);
      justify-content: space-between;

      button {
        background-color: $white;
      }
    }

    .reviews-slider {
      width: 100%;
      // background-color: red;
      height: auto;
      display: flex;
      margin-top: 2rem;
      position: relative;
    }

    .testimonial-item {
      max-height: unset;
      display: flex;
      height: 100%;
      padding: 2rem;
      flex-direction: column;
      width: calc(2rem + 100%);
      border: 1px solid $grey300;
      border-radius: $border-radius-lg;
      overflow: hidden;
      margin-right: 2rem;

      .testimonial-info {
        display: flex;
        //flex-direction: column;

        .author-photo {
          // width: 4.5rem;
          // height: 4.5rem;
          // padding: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          // background-color: $grey200;
          // overflow: hidden;
          // margin-top: 1.5rem;
          // border-radius: $border-radius-lg;
          // border: 1px solid $grey300;
          // backdrop-filter: blur(5px) saturate(1.2) contrast(1.4);
          // background: url('../assets/images//landing-banner-bg.svg');
          // background-size: cover;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: $border-radius-sm;
          }
        }
        h6 {
          color: $grey600;
          margin-bottom: -0rem;
          margin-top: 1rem;
        }
      }
    }
  }

  .business-services-grid {
    width: 100%;
    // background-color: honeydew;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $side-padding-mobile;
    margin: 1rem 0;
    margin-top: 0;

    .business-services-item {
      background-color: transparent;
      width: 100%;
      height: 100%;
      // aspect-ratio: 1;
      display: flex;
      flex-direction: column;
      border-radius: $border-radius-lg;
      overflow: hidden;
      justify-content: space-between;
      padding: 1rem;
      cursor: pointer;
      border: 1px solid $grey200;

      transition: 200ms ease-out;

      .business-services-item-main {
        height: fit-content;
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      &:hover {
        background-color: $grey200;
        transition: 200ms ease-out;
      }

      // img {
      //   width: 100%;
      //   aspect-ratio: 1 / 0.5;
      //   border-radius: $border-radius-lg;
      //   object-fit: cover;
      //   border-bottom-left-radius: 0;
      //   border-bottom-right-radius: 0;
      // }

      .business-services-item-icon {
        width: 2.2rem;
        height: 2.2rem;
        border-radius: $border-radius-lg;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: $grey800;
        margin-bottom: 1rem;
        border-radius: $border-radius-lg;
        border: 1px solid $grey300;
        backdrop-filter: blur(5px) saturate(1.2);
        background: url('../assets/images//landing-banner-bg.svg');
        background-size: cover;

        svg {
          width: 0.9rem;
          height: 0.9rem;
        }
      }

      .business-services-info {
        display: flex;
        flex-direction: column;

        h6 {
          margin-top: 0;
          margin-bottom: 0;

          svg {
            width: 1rem;
            height: 1rem;
            stroke: $orangeDark;
            margin-left: 0.5rem;
            transform: translateY(0.25rem);
            display: none;
          }
        }
      }
    }
  }
}
