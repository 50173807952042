.about-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: calc($header-height-with-banner + 2rem) $side-padding-desktop 0 $side-padding-desktop;
}

.tech-stack-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  .tech-stack-item {
    width: 100%;

    display: flex;
    align-items: center;
    border-radius: $border-radius-lg;
    overflow: hidden;
    border: 1px solid $grey200;
    margin-bottom: 1rem;
    height: 6rem;

    .tech-stack-item-name {
      width: 30vw;
      background-color: $grey100;
      height: 6rem;
      display: flex;
      align-items: center;
      padding-left: 1.5rem;

      h6 {
        margin: 0;
      }
    }

    .tech-stack-list {
      display: flex;
      padding: 1.5rem 1rem;

      flex: 1;
      display: flex;
      align-items: center;

      svg {
        width: 3rem;
        height: 3rem;
        stroke: unset;

        &:not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    }
  }
}

.founders-grid {
  width: 100%;

  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(3, 1fr);

  .founder-box {
    display: flex;
    border: 1px solid $grey200;
    border-radius: $border-radius-lg;
    padding: 2rem;

    display: flex;
    flex-direction: column;

    align-items: center;

    img {
      aspect-ratio: 1;
      border-radius: 50%;
      overflow: hidden;
      width: 50%;
      margin-bottom: 2rem;

      border: 1px solid $grey200;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.team-grid {
  width: 100%;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: $border-radius-lg;
    border-radius: 1px solid $grey200;
  }
}

@media screen and (max-width: 720px) {
  .about-container {
    background-color: $white;
  }
  .founders-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;

    .founder-box {
      flex-direction: row;
      align-items: center;
      padding: 1rem;

      img {
        width: 5rem;
        margin-right: 2rem;
        margin-bottom: 0;
      }

      div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .team-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
  }

  .tech-stack-container .tech-stack-item {
    flex-direction: column;
    width: 100%;
    height: unset;
    align-items: flex-start !important;

    .tech-stack-item-name {
      width: 100%;
      height: unset;
      padding: 1rem 1.5rem;
      font-size: 0.8rem;
    }

    .tech-stack-list {
      svg {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
