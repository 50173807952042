.offerings-container {
  width: 100%;
  min-height: 100vh;
  padding: 0 0 0 0;
  display: flex;
  flex-direction: column;
  background: url('../assets/images/landing-banner-bg.svg');
  background-size: 100% auto;

  .offerings-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    // background-color: $white;
    padding: calc($header-height-with-banner + 2rem) $side-padding-desktop 3rem
      $side-padding-desktop;
  }

  .offerings-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2rem $side-padding-desktop;
    background-color: $grey100;

    .offerings-main-grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 1.5rem;
    }
  }

  .digital-offerings-main-item {
    flex: 1;
    display: flex;
    gap: 1rem;
    // flex-direction: column;
    border: 1px solid $grey300;
    align-items: center;
    border-radius: $border-radius-lg;
    overflow: hidden;
    margin: 1rem 0;

    &:nth-child(odd) {
      flex-direction: row-reverse;
    }

    .offerings-main-item-info {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 1rem;

      h4 {
        color: $grey800;

        h4.grey500 {
          color: $grey500;
        }

        span {
          @extend h4;
          border-bottom: 5px solid $orange;
        }
      }
    }

    .offerings-main-item-image {
      flex: 1;
      height: 100%;
      overflow: hidden;
      display: flex;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .business-offerings-main-container {
    background-color: $white;
    .offerings-main-grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      grid-gap: 1.75rem;
    }

    .offerings-main-item {
      flex: 1;
      background-color: $white;
      display: flex;
      flex-direction: column;
      border: 1px solid $grey300;
      align-items: center;
      border-radius: $border-radius-lg;
      cursor: pointer;
      overflow: hidden;
      margin: 0;
      transition: 250ms ease-out;
      box-shadow: rgba($grey500, 0.2) 0px 2px 8px 0px;

      &:first-child {
        grid-column-start: 1;
        grid-column-end: 6;
      }

      &:nth-child(2) {
        grid-column-start: 6;
        grid-column-end: 10;
      }

      &:nth-child(3) {
        grid-column-start: 1;
        grid-column-end: 4;
      }

      &:nth-child(4) {
        grid-column-start: 4;
        grid-column-end: 7;
      }

      &:nth-child(5) {
        grid-column-start: 7;
        grid-column-end: 10;
      }

      &:nth-child(6) {
        grid-column-start: 1;
        grid-column-end: 5;
      }

      &:nth-child(7) {
        grid-column-start: 5;
        grid-column-end: 10;
      }

      &:nth-child(8) {
        grid-column-start: 1;
        grid-column-end: 4;
      }

      &:nth-child(9) {
        grid-column-start: 4;
        grid-column-end: 7;
      }

      &:nth-child(10) {
        grid-column-start: 7;
        grid-column-end: 10;
      }

      &:hover {
        transform: translateY(-0.25rem) scale(1.005);
        transition: 150ms ease-in-out;
        .offerings-main-item-image {
          .business-offering-link-project {
            background-color: $grey100;

            svg {
              stroke: $grey800;
            }
          }
        }
      }

      .offerings-main-item-info {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 0.5rem 1.5rem 1rem 1.5rem;

        small.orange {
          color: $orangeDark;
          filter: contrast(1.25);
        }

        p {
          color: $grey500;
          font-size: 0.975rem;
        }

        h5 {
          color: $grey800;

          display: flex;
          align-items: center;
          white-space: wrap;

          svg {
            width: 1.5rem;
            height: 1.5rem;
            stroke-width: 2px;
            margin-bottom: 2.5px;
            margin-right: 0.75rem;
            margin-left: -2px;
          }
        }
      }

      .offerings-main-item-image {
        width: 100%;
        height: 15rem;
        aspect-ratio: unset;
        overflow: hidden;
        flex: unset;
        // background-color: $grey100;
        display: flex;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        position: relative;

        backdrop-filter: blur(2px) saturate(1.4) contrast(1.1);
        background: url('../assets/images/landing-banner-bg.svg');
        background-size: cover;
        padding: 1rem;
        padding-bottom: 0;

        .offerings-main-item-image-overlay {
          position: absolute;
          top: 0;
          left: 0;
          // width: 100%;
          height: 100%;
          z-index: 33;

          background: radial-gradient(rgba($grey700, 0) 50%, rgba($grey700, 0.12));
        }

        .business-offering-link-project {
          position: absolute;
          top: 1rem;
          right: 1rem;
          width: 2.25rem;
          height: 2.25rem;
          border: 1px solid $grey800;
          background-color: $grey800;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: 250ms ease-out;
          border-radius: 50%;
          overflow: hidden;

          svg {
            stroke: $grey200;
            width: 1rem;
            stroke-width: 1.5px;
            height: 1rem;
            margin-right: 0.3rem;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: $border-radius-lg;
        }
      }
    }
  }

  .digital-offerings-listing {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
    padding: 3rem 0 0 0;

    .digital-offering-item {
      // height: 40rem;
      // background-color: azure;
      display: flex;
      flex-direction: column;

      h5 {
        height: 5.5rem;
        // background-color: red;
        // display: flex;
        align-items: flex-start;
        margin-bottom: 0;
        text-decoration: none;

        span {
          @extend h5;
          // border-bottom: 0.2rem solid $orange;
        }
      }

      p {
        margin: 0.75rem 0;
        text-decoration: none;
        height: 6rem;

        &.first-line {
          height: 5rem;
          // background-color: salmon;
        }
      }

      img {
        width: 100%;
        aspect-ratio: 3 / 2;
        object-fit: cover;
        margin: 0.5rem 0;
        border-radius: $border-radius-lg;

        box-shadow: inset 0 0 2rem 2rem black;
      }
    }
  }
}
@media only screen and (max-width: 620px) {
  .offerings-container {
    background: url('../assets/images/landing-banner-bg-mobile.svg');
  }
  .offerings-container .offerings-header .inline-form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;

    input {
      flex: 1;
    }
  }

  .offerings-header {
    padding: calc($header-height-with-banner + 2rem) $side-padding-mobile 3rem $side-padding-mobile !important;
  }

  .offerings-main-container {
    padding: 2rem $side-padding-mobile !important;
  }

  .offerings-container .business-offerings-main-container .offerings-main-grid {
    grid-template-columns: repeat(1, 1fr);

    .offerings-main-item {
      grid-column-start: unset !important;
      grid-column-end: unset !important;
    }
  }

  .offerings-container
    .business-offerings-main-container
    .offerings-main-item
    .offerings-main-item-info
    h5 {
    align-items: flex-start;

    .svg-icon {
      width: 1.5rem;
      margin-right: 0.75rem;

      svg {
        margin-right: 0;
      }
    }
  }

  .offerings-container .digital-offerings-main-item {
    flex-direction: column !important;
  }

  .offerings-container .digital-offerings-listing {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.5rem;
    padding: 2rem 0 0 0;

    .digital-offering-item {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        border-bottom: 1px solid $grey200;
        padding-bottom: 1rem;
      }

      h5 {
        height: auto;
        margin-top: 0;
        margin-bottom: 0.5rem;
        text-decoration: none;

        span {
          // border-bottom: 0.2rem solid $orange;
        }
      }

      p {
        margin: 0.75rem 0;
        text-decoration: none;
        height: auto;
        margin-bottom: 0;

        &.first-line {
          height: auto;
          // background-color: salmon;
        }
      }

      img {
        width: 100%;
        aspect-ratio: 3 / 2;
        object-fit: cover;
        margin: 0.5rem 0;
        border-radius: $border-radius-lg;

        box-shadow: inset 0 0 2rem 2rem black;
      }
    }
  }
}
