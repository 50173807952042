@font-face {
  font-family: FoundersGrotesk;
  src: url('../assets/fonts/FoundersGrotesk-Medium.otf');
}

@font-face {
  font-family: HauoraSans;
  src: url('../assets/fonts/Hauora-Medium.otf');
}

@font-face {
  font-family: HauoraSansSemibold;
  src: url('../assets/fonts/Bifocals.otf');
}

@font-face {
  font-family: OakSans;
  src: url('../assets/fonts/OakSans-Regular.ttf');
}

@font-face {
  font-family: OakSansBold;
  src: url('../assets/fonts/OakSans-SemiBold.ttf');
}

@font-face {
  font-family: RelativeBook;
  src: url('../assets/fonts/relative-book.ttf');
}

@font-face {
  font-family: TTHoves;
  src: url('../assets/fonts/TT\ Hoves\ Medium.otf');
}

@font-face {
  font-family: TTHovesLight;
  src: url('../assets/fonts/TT\ Hoves\ Regular.otf');
}

$body: 'RelativeBook';
$display: 'TTHoves';
$bodyBold: 'HauoraSansSemibold';
$light: 'OakSans';

h1,
h2,
h3,
h4,
h5,
h6,
p,
small,
a {
  // -webkit-user-select: none;
  // user-select: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

h1 {
  font-size: 2.8rem;
  font-family: $bodyBold;
  line-height: 1.2;
  letter-spacing: -0.04rem;
  color: $grey700;
  margin: 0.5rem 0;

  i {
    font-size: 2.8rem;
    font-family: $bodyBold;
    line-height: 1.2;
    letter-spacing: -0.04rem;
    color: $grey500;
    font-style: italic;
  }
}

h2 {
  font-size: 2.2rem;
  font-family: $bodyBold;
  line-height: 1.4;
  letter-spacing: -0.03rem;
  color: $grey700;
  margin: 0.5rem 0;
}

h3 {
  font-size: 1.7rem;
  font-family: $bodyBold;
  line-height: 1.5;
  // font-weight: 600;
  letter-spacing: -0.015rem;
  color: $grey700;
  margin: 0.5rem 0;
}

h4 {
  font-size: 1.5rem;
  font-family: $bodyBold;
  line-height: 1.5;
  letter-spacing: 0;
  color: $grey700;
  margin: 0.5rem 0;
}

h5 {
  font-size: 1.25rem;
  font-family: $display;
  line-height: 1.5;
  letter-spacing: -0.25px;
  color: $grey700;
  margin: 0.5rem 0;
}

h6 {
  font-size: 1.15rem;
  font-family: $display;
  line-height: 1.7;
  letter-spacing: -0.2px;
  color: $grey700;
  margin: 0.5rem 0;
}

p {
  font-size: 1.05rem;
  font-family: $body;
  line-height: 1.7;
  letter-spacing: 0px;
  color: $grey700;
  margin: 0.5rem 0;
  font-weight: 300;
}

a:not(.no-default-a) {
  font-size: 1rem;
  font-family: $bodyBold;
  line-height: 1.7;
  letter-spacing: 0.35px;
  color: $orangeDark;
  margin: 0.5rem 0;
  font-weight: 300;
  text-decoration: underline;
  text-underline-offset: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 200ms ease-out;

  &.link-border {
    background-color: rgba($color: $grey800, $alpha: 0.06);
    border: 1px solid $grey300;
    padding: 0.25rem 0.8rem 0.25rem 0.25rem;
    border-radius: 1rem;
    color: $grey800;
    text-decoration: none;
    transition: 200ms cubic-bezier(0.215, 0.61, 0.355, 1);

    &:hover {
      background-color: rgba($color: $grey800, $alpha: 0.12);
      transition: 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    .tag {
      background-color: $orangeDark;
      padding: 0.18rem 0.5rem;
      font-size: 0.7rem;
      font-weight: 100;
      color: $white;
      border-radius: 1rem;
      margin-right: 0.5rem;
      font-family: $light;
      display: flex;
      align-items: center;
    }

    svg {
      stroke-width: 0.8px;
    }
  }

  svg {
    margin-left: 0.5rem;
    stroke: $orangeDark;
    stroke-width: 1px;
    text-decoration: underline;
    text-underline-offset: 0.25rem;
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
  }

  &:hover {
    svg {
      transform: translateX(7px);
      transition: 200ms ease-out;
    }
  }
}

small {
  font-size: 0.875rem;
  font-family: $body;
  line-height: 1.7;
  letter-spacing: 0.4px;
  color: $grey600;
  margin: 0.5rem 0;
  font-weight: 300;
}

small.tag {
  font-size: 0.85rem;
  padding: 0.15rem 0 0.15rem 0.5rem;
  color: $grey700;
  border-left: 0.6rem solid $teal;
  text-transform: uppercase;
  letter-spacing: 0.12rem;
  font-family: $bodyBold;
  font-weight: 300;
}

svg {
  width: 1.5rem;
  height: 1.5rem;
  stroke: $grey700;
}

b {
  font-weight: bold;
}

.highlight {
  border-bottom: 0.2rem solid $orange;
}

@media screen and (max-width: 720px) {
  h1 {
    font-size: 9vw;
  }

  h2 {
    font-size: 7.5vw;
  }
  h5 {
    font-size: 5vw;
    letter-spacing: 0.25px;
    font-family: $display;
  }
  h6 {
    font-size: 1.25rem;
  }
  p {
    font-size: 4vw;
  }
}

h1 .lower-highlight {
  @extend h1;
  color: inherit;
  border-bottom: 0.4rem solid $orange;
}
