$white: #ffffff;
$grey100: #f9fbfa;
$grey200: #e8edeb;
$grey300: #d6dada;
$grey400: #889397;
$grey500: #6a7277;
$grey600: #47545a;
$grey700: #2d3337;
$grey800: #262e32;
$grey900: #000;

$orange: #f78400;
$teal: #008080de;
// $blue: #096fcf;
$blue: #2468a7;
$tan: #b9aa90;
$orangeDark: #f25c0cfe;
$red: #e50404;
