input,
textarea,
select {
  width: 100%;
  height: 3rem;
  border-radius: $border-radius-sm;
  border: 1px solid $grey800;
  padding: 0.35rem 1rem;

  outline: none;
  margin: 0.5rem 0;

  font-size: 1rem;
  color: $grey800;
  font-family: $body;

  &::placeholder {
    color: $grey600;
  }

  &:active,
  &:focus {
    border-color: $grey900;
    box-shadow: 0 0 0 2px rgba($color: $grey300, $alpha: 0.5);
  }

  &.invalid {
    border-color: $red;
    background-color: rgba($color: $red, $alpha: 0.1);
  }
}

textarea {
  min-height: 5rem;
}

select {
  background: transparent;
  appearance: none;
  position: relative;

  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 1rem top 50%;
  background-size: 0.65rem auto;
}

label {
  font-family: $body;
  color: $grey900;

  .subtext {
    font-size: 0.8rem;
    color: $grey500;
  }
}

.error-message {
  @extend small;
  font-size: 0.9rem;
  color: $red;
  margin-top: 0;
  visibility: hidden;
  opacity: 0;
  transition: 200ms ease-out;
  display: none;
  margin-bottom: 0;

  &.show {
    visibility: visible;
    opacity: 1;
    display: block;
    transition: 200ms ease-out;
  }
}

.form-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0.5rem 0;
  // margin-top: 0;
}

h2 {
  .highlight {
    @extend h2;
  }
}
