$header-height-with-banner: 6rem;
$header-height-without-banner: 3.5rem;

$side-padding-desktop: 7%;
$side-padding-mobile: 1.4rem;

$border-radius-sm: 0.25rem;
// $border-radius-lg: 0.75rem;
$border-radius-lg: 1rem;

:export {
  header-height-with-banner: $header-height-with-banner;
}
