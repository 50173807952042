.footer-container {
  width: 100%;
  background-color: $grey200;
  display: flex;
  flex-direction: column;
  padding: 2rem $side-padding-desktop;

  .footer-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $grey300;
    padding-bottom: 2rem;

    .footer-left {
      display: flex;
      flex-direction: column;

      .footer-links {
        display: flex;
        align-items: center;

        a {
          color: $grey800;
          font-family: $body;
          font-size: 1rem;
          margin-right: 1rem;
          text-decoration: none;

          &:not(:last-child):after {
            content: '';
            width: 0.4rem;
            height: 0.4rem;
            background-color: $grey300;
            margin-left: 1rem;
            border-radius: 50%;
          }
        }
      }

      small {
        color: $grey500;
      }
    }

    .footer-right {
      display: flex;
      align-items: center;

      a.footer-socials {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin-left: 0.75rem;
        padding: 0.5rem;
        border: 1.5px solid $grey300;

        img {
          //   width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .footer-bottom {
    width: 100%;
    padding-top: 2rem;

    small {
      color: $grey500;
      white-space: nowrap;

      a {
        display: unset;
        color: $grey600;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .footer-container {
    width: 100%;
    background-color: $grey100;
    display: flex;
    flex-direction: column;
    padding: 2rem $side-padding-mobile;

    .footer-top {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      border-bottom: 1px solid $grey300;
      padding-bottom: 1.5rem;

      .footer-left {
        display: flex;
        flex-direction: column;

        .footer-links {
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          a {
            color: $grey800;
            font-family: $body;
            font-size: 1rem;
            margin-right: 1rem;
            text-decoration: none;

            &:not(:last-child):after {
              content: '';
              display: none;
              width: 0.4rem;
              height: 0.4rem;
              background-color: $grey300;
              margin-left: 1rem;
              border-radius: 50%;
            }
          }
        }

        small {
          color: $grey500;
        }
      }

      .footer-right {
        display: flex;
        align-items: center;

        a.footer-socials {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          display: flex;

          align-items: center;
          justify-content: center;
          overflow: hidden;
          padding: 0.5rem;
          border: 1.5px solid $grey300;

          &:first-child {
            margin-left: 0;
          }

          img {
            //   width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .footer-bottom {
      width: 100%;
      padding-top: 1.5rem;

      small {
        color: $grey500;
        white-space: wrap;
        width: 100%;
        a {
          display: unset;
          color: $grey700;
        }
      }
    }
  }
}
