@import './palette';
@import './variables';
@import './typography';
@import './inputs';

@import './landing';
@import './contact';
@import './offerings';
@import './portfolio';
@import './about';

@import './button';
@import './header';
@import './footer';

* {
  box-sizing: border-box;
  font-size: 1rem;
  color: $grey700;
  font-family: $body, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 300;
}

html,
body {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
}

.page {
  padding-top: $header-height-with-banner;
}

// main {
//   margin-top: $header-height-with-banner;
// }
ul {
  padding-inline-start: 0;
  list-style: none;
  width: 100%;
  margin-block-start: 0;

  li {
    display: flex;
    list-style: none;
    margin: 0rem 0;
    align-items: baseline;

    div.check {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      margin-right: 1rem;
      aspect-ratio: 1;

      svg {
        width: 1rem;
        height: 1rem;
        stroke: $grey800;
        margin-top: 0.25rem;
        stroke-width: 1.5px;
        transform: rotate(5deg);
      }
    }

    &.li-reduced-padding {
      p {
        margin: 0.2rem 0;
      }
    }
  }
}

.inline-form {
  display: flex;

  input {
    width: min(30vw, 18rem);
    margin: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  button {
    height: 3rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@media screen and (max-width: 720px) {
  ul {
    padding-inline-start: 0;
    list-style: none;
    width: 100%;
    margin-block-start: 0;

    li {
      display: flex;
      list-style: none;
      margin: 0rem 0;
      align-items: baseline;

      p {
        margin: 0.2rem 0;
      }

      div.check {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        margin-right: 1rem;
        aspect-ratio: 1;

        svg {
          width: 1rem;
          height: 1rem;
          stroke: $grey800;
          margin-top: 0.25rem;
          stroke-width: 1.5px;
          transform: rotate(5deg);
        }
      }

      &.li-reduced-padding {
        p {
          margin: 0.2rem 0;
        }
      }

      p {
        font-size: 1.15rem;
      }
    }
  }

  .inline-form {
    display: flex;
    width: 100%;

    input {
      flex: 1;
      height: 3rem;
      margin: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    button {
      height: 3rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
