.header-outer-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  z-index: 100;
}

.header-desktop-container {
  // height: $header-height-with-banner;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  pointer-events: all;

  background-color: $white;

  &.no-banner {
    height: $header-height-without-banner;
  }

  &.no-scroll,
  &.mobile-border {
    background-color: transparent;

    .header-main {
      border-color: transparent;
    }

    .header-banner {
      border-bottom: 1px solid transparent;
    }
  }
  &.mobile-border {
    .header-banner {
      border-bottom-color: $grey300;
    }
  }

  //   &.no-scroll {
  //     background-color: $grey900;

  //     .nav-link,
  //     .nav-trigger * {
  //       color: $grey100 !important;
  //       stroke: $grey100;
  //     }

  //     .header-main {
  //       border-color: transparent;
  //     }

  //     .header-banner {
  //       background-color: $grey800;

  //       small {
  //         color: $grey400;

  //         a {
  //           color: $grey200;

  //           svg {
  //             stroke: $grey200;
  //           }
  //         }
  //       }
  //     }

  //     button.primary {
  //       background-color: $grey200 !important;
  //       color: $grey800 !important;
  //     }

  //     button.secondary {
  //       background-color: transparent !important;
  //       color: $grey200 !important;
  //       border-color: $grey200 !important;
  //     }
  //   }

  .header-banner {
    height: calc($header-height-with-banner - $header-height-without-banner - 1px);
    background-color: transparent;
    border-bottom: 1px solid $grey300;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $side-padding-desktop;
    backdrop-filter: blur(2px) saturate(1.5) contrast(1);

    .header-banner-left {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;

      .header-banner-tag {
        background-color: $orangeDark;
        padding: 0.25rem 0.6rem;
        border-radius: $border-radius-lg / 2;
        color: $white;
        font-size: 0.7rem;
        letter-spacing: 0.65px;
        margin-right: 0.5rem;
      }
    }

    small {
      color: $grey600;
      font-size: 0.85rem;
      letter-spacing: 0.2px;
    }

    a {
      color: $grey800;
      font-family: $body;
      font-size: 0.9rem;

      svg {
        stroke: $grey800;
      }
    }
  }

  .header-main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $side-padding-desktop;
    border-bottom: 1px solid $grey300;
    height: $header-height-without-banner;
    position: sticky;
    top: 0;

    .header-main-left {
      flex: 1;
      display: flex;
      align-items: center;

      .header-logo {
        width: 8rem;
        height: 4rem;
        padding: 1.05rem 0;

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      nav {
        width: 100%;
      }
    }
  }

  a.contact-us {
    font-size: 1rem;

    svg {
      stroke-width: 2px;
    }
  }
}

.header-main-left {
  button {
    all: unset;
  }

  .nav-root {
    position: relative;
    display: flex;
    z-index: 9999;
  }

  .nav-list {
    display: flex;
    justify-content: center;
    padding: 0;
    border-radius: $border-radius-sm;
    list-style: none;
    margin: 0;

    li {
      &:not(:last-child) {
        border-right: 1px solid $grey300;
      }
    }
  }

  .nav-trigger,
  .nav-link {
    padding: 0 1rem;
    outline: none;
    user-select: none;
    line-height: 1;
    font-size: 1rem;
    font-family: $body;
    letter-spacing: 0.1px;
    color: $grey800;
    margin: 0 1rem;
    width: fit-content;
    font-weight: 300;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    text-transform: capitalize;
    text-decoration: none;
    cursor: pointer;

    p {
      display: flex;
      align-items: center;
      font-family: inherit;
      letter-spacing: inherit;
      font-size: inherit;
      margin: 0rem 0;

      a {
        all: inherit;
      }
    }
  }
  .nav-trigger:focus,
  .nav-link:focus {
    text-decoration: underline;
    text-underline-position: 0.25rem;
  }

  .nav-trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
  }

  .nav-link {
    display: flex;
    text-decoration: none;

    border-right: 1px solid transparent;
  }

  .nav-content {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    animation-duration: 250ms;
    animation-timing-function: ease;
    z-index: 99999;
    padding: 1.5rem 2rem;
    display: flex;
    gap: 4rem;
    overflow: hidden;
    height: fit-content;

    a {
      color: $grey500;
      text-decoration: none;
      font-family: $body;
      &:hover {
        color: $grey800;
      }
    }

    .nav-column {
      max-width: calc(50% - 2rem);
    }
  }
  .nav-content[data-motion='from-start'] {
    animation-name: enterFromLeft;
  }
  .nav-content[data-motion='from-end'] {
    animation-name: enterFromRight;
  }
  .nav-content[data-motion='to-start'] {
    animation-name: exitToLeft;
  }
  .nav-content[data-motion='to-end'] {
    animation-name: exitToRight;
  }
  @media only screen and (min-width: 600px) {
    .nav-content {
      width: auto;
    }
  }

  .nav-indicator {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 10px;
    top: 100%;
    overflow: hidden;
    z-index: 9999;
    transition: width, transform 250ms ease;
  }
  .nav-indicator[data-state='visible'] {
    animation: fadeIn 200ms ease;
  }
  .nav-indicator[data-state='hidden'] {
    animation: fadeOut 200ms ease;
  }

  .nav-viewport {
    position: relative;
    transform-origin: top center;
    margin-top: 2rem;
    min-width: 30vw;
    max-width: 60vw;
    background-color: $white;
    z-index: 99999;
    border-radius: $border-radius-lg;
    border: 1px solid $grey200;
    // box-shadow: rgba($color: $grey500, $alpha: 0.25) 0px 5px 5px 0px;
    // rgba($color: $grey600, $alpha: 0.25) 0px 10px 20px -10px;
    min-height: 40vh;
    max-height: calc(100vh - $header-height-with-banner);
    height: fit-content !important;
    transition: width, height, 300ms ease;
    // border: 1px solid black;
  }
  .nav-viewport[data-state='open'] {
    animation: scaleIn 200ms ease;
  }
  .nav-viewport[data-state='closed'] {
    animation: scaleOut 200ms ease;
  }
  @media only screen and (min-width: 600px) {
    .nav-viewport {
      width: calc(100vw - 2 * $side-padding-desktop);
    }
  }

  .viewport-position {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    top: 100%;
    // height: 100vh;
    left: 0;
    overflow: hidden;
    // height: 60vh;
    perspective: 1000px;
  }

  .caret-down {
    all: initial;
    position: relative;
    top: 0;
    transition: transform 250ms ease;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;

    svg {
      width: 1rem;
      height: 1rem;
      transform: none !important;
      transition: none !important;
      animation: none;
      stroke: $grey700;
      width: inherit;
      height: inherit;
      stroke-width: 1.5;
    }
  }

  .nav-arrow {
    position: relative;
    top: 70%;
    background-color: transparent;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border-top-left-radius: 2px;
  }

  @keyframes enterFromRight {
    from {
      opacity: 0;
      transform: translateX(200px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes enterFromLeft {
    from {
      opacity: 0;
      transform: translateX(-200px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes exitToRight {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(200px);
    }
  }

  @keyframes exitToLeft {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(-200px);
    }
  }

  @keyframes scaleIn {
    from {
      opacity: 0;
      transform: rotateX(-30deg) scale(0.9);
    }
    to {
      opacity: 1;
      transform: rotateX(0deg) scale(1);
    }
  }

  @keyframes scaleOut {
    from {
      opacity: 1;
      transform: rotateX(0deg) scale(1);
    }
    to {
      opacity: 0;
      transform: rotateX(-10deg) scale(0.95);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

@media screen and (max-width: 720px) {
  .header-outer-container {
    width: 100vw;
    overflow: hidden;
    height: 100vh;

    .header-desktop-container {
      border-bottom: 1px solid $grey300 !important;

      .header-banner {
        z-index: 101;
        padding: 0 $side-padding-mobile;

        .header-banner-left {
          width: 100%;

          small {
            width: max-content;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-bottom: 0.35rem;
          }
        }

        svg {
          stroke: $orangeDark;
          width: 1.4rem;
          height: 1.4rem;
        }
      }
      .header-main {
        padding: 0 $side-padding-mobile;

        .header-main-left {
          .header-logo {
            padding: 1.2rem 0;
          }
        }

        .nav-root-mobile {
          ul.nav-list {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .viewport-position {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    top: calc(-1 * ($header-height-with-banner - $header-height-without-banner));
    z-index: 99999;
    left: 0;
    overflow: hidden;
    // height: 60vh;
    perspective: 1000px;
    background-color: rgba($color: $grey800, $alpha: 0.5);

    &[data-state='open'] {
      animation: fadeIn 100ms ease-in;
    }

    &[data-state='open'] {
      animation: fadeOut 100ms ease-on;
    }

    .mobile-viewort {
      background-color: white;
      width: calc(100vw - 5 * $side-padding-mobile);
      margin-left: 3 * $side-padding-mobile;
      max-height: calc(100vh - $header-height-with-banner - 2rem);
      overflow-x: hidden;
      overflow-y: scroll;
      margin-top: $header-height-without-banner;
      border-radius: $border-radius-lg;
      padding: 1.5rem;
      margin-bottom: 5rem;

      &[data-state='open'] {
        animation: scaleIn 200ms ease;
      }

      &[data-state='closed'] {
        animation: scaleOut 200ms ease;
      }
      .mobile-content {
        &[data-state='closed'] {
          animation: fadeOut 500ms ease-out;
        }

        .mobile-header-heading {
          font-size: 1.15rem;
          color: $grey800;
          text-decoration: none;
          font-family: $bodyBold;
          margin: 0.75rem 0;
          width: 100%;
          letter-spacing: 0.25px;

          &:not(:first-child) {
            border-top: 0.5px solid $grey200;
            padding-top: 0.725rem;
          }
        }

        a {
          font-family: $body;
          color: $grey700;
          text-decoration: none;
          margin: 0.25rem 0;
          font-size: 1rem;
        }
      }
    }
  }

  .nav-arrow {
    display: none;
  }
}
